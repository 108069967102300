nav {
  background-color: rgb(125, 123, 247);
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 2px 6px rgba(20, 20, 20, 0.904);
  width: 100%;
  z-index: 19;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo a {
  font-size: 3.15rem;
  margin-left: 7px;
  color: white;
  margin-bottom: -2px;
}
.logo svg {
  width: 30px;
  height: 30px;
}

.navFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helpIcon svg {
  color: white;
  font-size: 3.5rem;
  margin-bottom: -3px;
}
.helpIcon,
.navSpan {
  cursor: pointer;
}

.helpIcon.outline svg {
  margin-left: 10px;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.cls-1 {
  stroke-width: 8px;
}
.cls-2 {
  stroke-width: 8.31px;
}
.cls-3 {
  stroke-width: 8.74px;
}
.cls-4 {
  stroke-width: 4.4px;
}
.cls-5 {
  stroke-width: 4.36px;
}

.navSpan {
  display: none;
  color: #fff;
  font-size: 1.7rem;
}
.navSpan span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navSpan svg {
  font-size: 3.5rem;
  margin-right: -5px;
}

@media screen and (min-width: 500px) {
  .logo svg {
    width: 30px;
    height: 30px;
  }
  .logo {
    margin-left: 77px;
  }

  .logo a {
    font-size: 2.9rem;
    margin-left: 7px;
  }
  nav {
    padding: 7px 0;
  }

  .navSpan {
    display: block;
  }

  .helpIcon.outline svg {
    margin-left: 15px;
  }
}
