* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
html {
  font-size: 8px;
  overflow-x: hidden;
}

body {
  font-family: 'Nunito', sans-serif;
  background-color: #b8b5ff;
  overflow-x: hidden;
  position: relative;
}

h1 {
  font-size: 3.1rem;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 40px;
}

.cameraH1 {
  margin-top: 30px;
}

h2 {
  line-break: anywhere;
}
p {
  font-size: 1.6rem;
}
li {
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}

img {
  width: 100%;
}

.wrapper {
  max-width: 1700px;
  width: 90%;
  margin: 0 auto;
}

.hidden {
  display: none;
}

.closeConnect {
  background-color: #f55c47;
  padding: 10px 0;
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: fixed;
  bottom: 13px;
  left: 20px;
  right: 20px;
  color: white;
  font-weight: 700;
  box-shadow: 1px 2px 6px black;
  font-size: 2.4rem;
}

.mainBody {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

@media screen and (min-width: 365px) {
  html {
    font-size: 10px;
  }
}
