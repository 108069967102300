.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
}
.loaderContainer {
  width: 100%;
  background-color: #b8b5ff;
  position: fixed;
  top: 82px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
